import React, { useEffect, useState } from 'react'
import "./Footer1.css"
import logo from "../Images/nav-ogo.png"
import whatsup from "../Images/whatsup.png"
import { Icon } from '@iconify/react/dist/iconify.js';
import axios from 'axios';
import Backend_url from '../../Important/BackEnd';
import Token from '../../Important/TOKEN';

function Footer1() {
  const [HPbodycardfooter, setHpbodycardFooter] = useState(null);
  const [showWhatsAppIcon, setShowWhatsAppIcon] = useState(false);
  const [contactUsfooter, setcontactusfooter] = useState(null);
  const [followuslogo, setfollouslogo] = useState(null)
  const [mapaddress, setmapaddress] = useState(null)
  const [Bottomlogo, setBottomlogo] = useState({})

  useEffect(() => {
    const handleScroll = () => {
      const containerHeight = document.querySelector('.footer1-container').offsetHeight;
      const scrollY = window.scrollY;

      if (scrollY > containerHeight) {
        setShowWhatsAppIcon(true);
      } else {
        setShowWhatsAppIcon(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);



  const handlwhatsapp = () => {
    debugger
    window.location.href = `whatsapp://send/?phone=${Bottomlogo.wanumber}&text= Hello Dear, i am from Shreejob`;
  };

  const handleDialUp = () => {
    debugger
    const phoneNumber = Bottomlogo?.phone
    if (phoneNumber) {
      window.location.href = `tel:${phoneNumber}`;
    } else {
      alert('Please enter a phone number first.');
    }
  };

  // -----------------------Footer data---------------------------
  const footerdata = async () => {
    try {
      await axios.get(`${Backend_url}/HPBodyCard/getall`, {
        headers: {
          'Authorization': 'Bearer ' + Token
        }
      }).then((res) => {
        console.log(res.data)
        const foterdata = res.data.data
        foterdata.map((item, index) => {
          if (item.Title == "FooterAbout") {
            setHpbodycardFooter(item)
          }
        })
      })
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    footerdata()
  }, [])

  useEffect(() => {
    console.log(HPbodycardfooter)
  }, [HPbodycardfooter]);

  const fetchjson = async () => {
    try {
      axios.get(`${Backend_url}/jsonroute/get`,
        {
          headers: {
            'Authorization': 'Bearer ' + Token,
          },
        }
      ).then((res) => {
        const alljson = res.data.json
        alljson.map((jsondata) => {
          const parsedObject = JSON.parse(jsondata.JsonObject)
          if (parsedObject.name == "footerfollowus") {
            setfollouslogo(parsedObject?.data)
          }
          if (parsedObject.name == "contactus") {
            setcontactusfooter(parsedObject?.data)
          }
          if (parsedObject.name == "mapaddress") {
            setmapaddress(parsedObject?.data)
          }
          if (parsedObject.name == "dialup") {
            setBottomlogo(parsedObject?.data[0])
          }
        })
        console.log(res.data)
      })
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    console.log(followuslogo)
  }, [followuslogo]);

  useEffect(() => {
    console.log(Bottomlogo)
  }, [Bottomlogo]);

  useEffect(() => {
    console.log(mapaddress)
  }, [mapaddress]);

  useEffect(() => {
    fetchjson()
  }, []);

  // --------------------------Handleuserlogin----------------------------------
  const handleUserlogin = async (route) => {
    try {
      window.open(route, '_blank');
    } catch (error) {
      console.log(error);
    }
  }


  // -----------------------handlegmail-----------------------------------------------
  const handleEmailClick = (email) => {

    // const subject = encodeURIComponent('Hello');
    // const body = encodeURIComponent('I wanted to reach out about...');

    // Gmail URL format: https://mail.google.com/mail/u/0/?view=cm&fs=1&to=recipient&su=subject&body=body
    const gmailUrl = `http://${email}/`;

    // Open Gmail in a new window
    window.open(gmailUrl, '_blank');
  };

  return (
    <>

      <div className="footer1-container bg-blue-950">
        <div className='footer1 px-2'>
          <img className={`sm:!m-0`} src={HPbodycardfooter?.CardImage} alt="" />
          <p>{HPbodycardfooter?.Details}</p>

          <h1>Follow on</h1>
          <ul className='follow-on sm:!m-0 sm:gap-[15px]'>
            <li onClick={() => window.open(followuslogo?.instagram)} className={`sm:flex sm:items-center sm:justify-center`}><i class="fa-brands fa-instagram sm:!m-0"></i></li>
            <li onClick={() => window.open(followuslogo?.facebook)} className={`sm:flex sm:items-center sm:justify-center`}><i class="fa-brands fa-facebook sm:!m-0"></i></li> {/* Correct YouTube Icon */}
            <li onClick={() => handleEmailClick(followuslogo?.gmail)} className={`sm:flex sm:items-center sm:justify-center`}><i class="fa-solid fa-envelope sm:!m-0"></i></li>
            {/* <li onClick={() => window.open(followuslogo?.twitter)} className={`sm:flex sm:items-center sm:justify-center`}><i class="fa-brands fa-twitter sm:!m-0"></i></li> */}
            <li onClick={() => handleUserlogin(followuslogo?.user)} className={`sm:flex sm:items-center sm:justify-center`}><i class="fa-solid fa-user sm:!m-0"></i></li>

            {/* <li onClick={() => window.open(followuslogo?.youtube)} className={`sm:flex sm:items-center sm:justify-center`}><i className="fab fa-google sm:!m-0"></i></li> Replace Google+ with Google */}
          </ul>
        </div>
        <div className='footer1  '>
          <h1>CONTACT US</h1>
          <p className=' flex items-start gap-1 justify-start'>
            <Icon icon="mdi:address-marker-outline" style={{ color: "white", fontSize: '70px', height: 'auto', marginTop: '3px' }} />
            {/* SHREE JOB CONSULTANCY Shop- 311B, 3rd Floor, Lalganga Midas Complex, Opp. to Central College, Bilaspur Road, Fafadih, Raipur, Chhattisgarh 492009 */}
            {contactUsfooter?.Address}

          </p>

          <p className=' flex items-start gap-1 justify-start'>
            <Icon icon="mage:contact-book" style={{ color: "white", fontSize: '39px', height: 'auto', marginTop: '3px' }} />
            {/* CONTACT NO. - 9179995552 / 8103232328 / 9111104359 / 9977604359 / 8877104359 */}
            CONTACT NO.-{contactUsfooter?.contact}
          </p>
          <li className=' w-full flex items-start text-white gap-1 justify-start'>
            <Icon icon="clarity:email-solid" style={{ color: "white", fontSize: '20px', height: 'auto', marginTop: '3px' }} />
            {/* shreejobconsultant@gmail.com */}
            {contactUsfooter?.email}
          </li>
        </div>
        <div className='footer1'>
          <h1> QUICK LINKS</h1>
          <ul className={`w-[58%] sm:w-full sm:pl-[23px]`} style={{ textAlign: 'left', display: 'flex', alignItems: 'start', flexDirection: 'column' }}>
            <li className='text-white text-left'>About Us</li>
            <li className='text-white text-left'>Current Opening</li>
            <li className='text-white text-left'>contact Us</li>
            <li className='text-white text-left'>terms & condition</li>
          </ul>
        </div>
        <div className='footer1'>
          <h1>Follow Us On</h1>

          {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14872.683568447552!2d81.639258!3d21.264702!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a28ddfbfd8c027f%3A0xd861bf21fa7a9693!2sShree%20Job%20Consultant!5e0!3m2!1sen!2sin!4v1731045957127!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
          <iframe className={`sm:!m-0`} src={mapaddress}
            width="350"
            height="200"
            style={{
              border: 0,
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', // Adding box shadow
              borderRadius: '10px', // Optional: add border radius for smooth edges
            }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade">

          </iframe>

        </div>
      </div>
      {/* {showWhatsAppIcon && ( */}
      <i class="fa-brands fa-whatsapp whatup-icon" onClick={handlwhatsapp}></i>
      <i style={{ marginBottom: '10px' }} onClick={handleDialUp} class="fa-solid fa-phone dial-icon"></i>

      {/* )} */}
    </>
  )
}

export default Footer1