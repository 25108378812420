import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slider.css"

import slid1 from "../Images/slid1.jpg"
import slid2 from "../Images/slid2.jpg"
import slid3 from "../Images/slid3.jpg"
import slid4 from "../Images/slid4.jpg"
import slid5 from "../Images/slid5.jpg"
import slid6 from "../Images/slid6.jpg"
import slid7 from "../Images/slid7.jpg"
import slid8 from "../Images/slid8.jpg"
import slid9 from "../Images/slid9.jpg"
import slid10 from "../Images/slid10.jpg"
import slid11 from "../Images/slid11.jpg"
import slid12 from "../Images/slid12.png"
import axios from 'axios';
import Backend_url from '../../Important/BackEnd';
import Token from '../../Important/TOKEN';

const ArrowButton = ({ className, onClick, arrowType }) => {
  return (
    <button
      className={className}
      onClick={onClick}
      style={{
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        background: '#ddd',
        border: '0px solid #000',
        cursor: 'pointer',
        position: 'absolute',
        bottom: '-50px', // Position below the slider
        zIndex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {arrowType === 'prev' ? '<' : '>'} {/* Custom Arrow Symbols */}
    </button>
  );
};

function Slider1() {
  const [slider, setslider] = useState([]);
  const [isPaused, setIsPaused] = useState(false);
  const [marqueContent, setmarqueContent] = useState([]);
  const setting = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: true,
    prevArrow: <ArrowButton arrowType="prev" />, // Custom Previous Arrow
    nextArrow: <ArrowButton arrowType="next" />, // Custom Next Arrow

  };

  const slidImge = [
    { id: 1, img: <img src={slid1} alt='' /> },
    { id: 1, img: <img src={slid2} alt='' /> },
    { id: 1, img: <img src={slid3} alt='' /> },
    { id: 1, img: <img src={slid4} alt='' /> },
    { id: 1, img: <img src={slid5} alt='' /> },
    { id: 1, img: <img src={slid6} alt='' /> },
    { id: 1, img: <img src={slid7} alt='' /> },
    { id: 1, img: <img src={slid8} alt='' /> },
    { id: 1, img: <img src={slid9} alt='' /> },
    { id: 1, img: <img src={slid10} alt='' /> },
    { id: 1, img: <img src={slid11} alt='' /> },
    { id: 1, img: <img src={slid12} alt='' /> },
  ]

  // --------------slider-----------------------
  const Sliderfetch = async function Sliderfetch() {
    try {
      axios.get(`${Backend_url}/api/sliders/all`, {
        headers: {
          'authorization': 'Bearer ' + Token,
        },
      }).then((res) => {
        console.log(res)
        setslider(res.data[0].Imagepaths)
      })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    console.log(slider)
  }, [slider]);

  useEffect(() => {
    Sliderfetch();
  }, []);

  // const marqueeContent = [
  //   {
  //     id: 1, text1: "Chemist/Sr.Chemist Pellet Plant Department ( reputed integrated steel plant ",
  //     text2: "4to8Year YRS, Bsc/Msc, Raipur C.G, 35to40k Per Month INTERVIEW - 7th/8th/9th Oct 2024( Walk-in Interview Call 08103232328 )"
  //   },
  //   { id: 2, text1: "CRO/Shift In Charge Process 6x500TPD Sponge Iron ( reputed integrated steel plant )", text2: "4to8Year YRS, Bsc/Msc/Diploma/BE, Champa C.G, 35t650k Per Month INTERVIEW - 7th/8th/9th Oct 2024( Walk-in CRO/Shift In Charge Process 6x500TPD Sponge Iron 5th/6th/7th August 2024 Call 08103232328 Shree JOB CONSULTANT )" },
  //   { id: 3, text1: "Engineer/Sr.Engineer Mechanical Sponge Iron ( reputed integrated steel plant )", text2: " 4to8Year YRS, Be/Diploma Mechanical, Champa C.G, 40to65K Per Month INTERVIEW - 7th/8th/9th Oct 2024( WALK-IN Engineer/Sr.Engineer Mechanical Sponge Iron Call 08103232328 Shree JOB CONSULTANT Office Address :- Lalganga Midas 3rd Floor Fafadhi Raipur C.G )" },
  //   { id: 4, text1: "Manager Mechanical 6x500TPD Sponge Iron Plant ( reputed integrated steel plant )", text2: "8to15Year In Steel Plant YRS, Be/Diploma Mechanical, Champa C.G, 80to1Lacs Per Month INTERVIEW - 7th/8th/9th Oct 2024( Walk-in Manager Mechanical 6x500TPD Sponge Iron Plant Call For Details 08103232328 Shree JOB CONSULTANT )" },
  //   { id: 5, text1: "Telecaller/Female Counsellor ( Education )", text2: "1-2yrs YRS, Any Graduate, Bhilai/Raipur, 10-15k INTERVIEW - 7th/8th/9th Oct 2024( English Communication should be good/ and knowledge of abroad education Contact : 9111104359 )" },
  //   { id: 6, text1: "Process Cordinator/Sales Cordinator ( Construction company )", text2: "2-3yrs YRS, B.com, Fafadih, 12-20k INTERVIEW - 7th/8th/9th Oct 2024( Contact No.-9111104359 )" },
  //   { id: 7, text1: "Jr.Accountant/Sr. Accountant ( Industry )", text2: "1-5yrs YRS, B.com/ GST Knowledge, Telibandha,shankar Nagar, 10-35k INTERVIEW - 7th/8th/9th Oct 2024( .Contact on 91111104359 )" },
  //   { id: 8, text1: "Engineer/Sr.Engineer Mechanical Sponge Iron ( reputed integrated steel plant )", text2: "3to8Year in Sponge Iron YRS, Be/Diploma Mechanical, Tilda Raipur C.G, 35to40k Per Month INTERVIEW - 7th/8th/9th Oct 2024( Walk-in Interview Engineer/Sr.Engineer Mechanical Sponge Iron Date 20th July Office Address :- Lalganaga Midas 3rd/4th Floor Fafadhi Raipur C.G Call 08103232328 )" },
  //   { id: 9, text1: "Manager/Sr.Manager QC Sponge Iron ( reputed integrated steel plant )", text2: "8to20Year In Iron Ore Sponge Iron YRS, Bsc/Msc, Tilda Raipur C.G, 70to80k Per Month INTERVIEW - 7th/8th/9th Oct 2024( Walk-in Manager/Sr.Manager QC Sponge Iron 15th/16th/17th July 2024 Call 08103232328 )" },
  //   { id: 10, text1: "Store Officer Steel Plant ( reputed integrated steel plant )", text2: "2to5Year YRS, Any Graduate, Raipur C.G, 22nd/23rd/24th July INTERVIEW - 7th/8th/9th Oct 2024( Walk-in Interview 28th/29th/30th August 2024 Store Officer Steel Plant Call 08103232328 )" },

  // ];

  // --------------   fetch marquecontents --------------------------------
  const fetchjson = async () => {
    try {
      axios.get(`${Backend_url}/jsonroute/get`,
        {
          headers: {
            'Authorization': 'Bearer ' + Token,
          },
        }
      ).then((res) => {
        const alljson = res.data.json
        alljson.map((jsondata) => {
          const parsedObject = JSON.parse(jsondata.JsonObject)
          if (parsedObject.name == "WalkOn") {
            setmarqueContent(parsedObject?.data)
          }
        })
        console.log(res.data)
      })
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchjson()
  }, []);

  useEffect(() => {
    console.log(marqueContent)
  }, [marqueContent]);

  const handleMouseEnter = () => {
    setIsPaused(true);
  };

  // Function to resume animation when mouse leaves
  const handleMouseLeave = () => {
    setIsPaused(false);
  };

  return (
    <div className='main-container'>

      <div className="slider-container">
        <Slider {...setting} className="carousel w-full">
          {slider?.map((item, index) => (
            <div key={index} className='slide-image'>
              {/* {item.img} */}
              <img src={item} alt='' />
            </div>
          ))}

        </Slider>
      </div>

      <div className="marquee-container sm:overflow-hidden">
        <h1>WALK IN</h1>
        <div className="marquee-container1 sm:!shadow-none ">
          <div className={`marquee ${isPaused ? 'paused' : ''}  `}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            {marqueContent?.map((item, index) => (
              <div key={index} className="marquee-item" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
              >
                <p className='p1'>{item.text1}</p>
                <p className='p2'> {item.text2}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Slider1
